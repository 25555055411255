export class TestSampleFakeData {
	public static testSample = [
		{
			id: 1,
			jobNo: 'TC-65-001',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'EM',
			status: 'Payment',
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 2,
			jobNo: 'TC-65-002',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'EM',
			status: 'Payment',
			sample: 'EMC Shielding Room Testing (At Delta Samutprakarn)',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 3,
			jobNo: 'TC-65-003',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'EM',
			status: 'Payment',
			sample: 'Remote of Drone',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Pornpan Chansriuoma',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 4,
			jobNo: 'TC-65-004',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'EM',
			status: 'Payment',
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 5,
			jobNo: 'TC-65-005',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'EM',
			status: 'Payment',
			sample: 'Pushlog Gateway',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 6,
			jobNo: 'TC-65-006',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'EM',
			status: 'Payment',
			sample: 'Pushlog Gateway',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 7,
			jobNo: 'TC-65-007',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'EM',
			status: 'Payment',
			sample: 'Wireless Music Streaming Amplifier',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 8,
			jobNo: 'TC-65-008',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'TC',
			status: 'Accept',
			sample: 'อากาศยานซึ่งไม่มีนักบิน (Unmanned Aerial Vehicle)',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 9,
			jobNo: 'TC-65-009',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'TC',
			status: 'Accept',
			sample: 'EMC Shielding Room Testing (At Delta Samutprakarn)',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 10,
			jobNo: 'TC-65-010',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'TC',
			status: 'Payment',
			sample: 'ECMU-IIOT',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 11,
			jobNo: 'TC-65-011',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'TC',
			status: 'Payment',
			sample: 'ECMU-IIOT',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: 'EMF of LoRa 920-925 MHz',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 12,
			jobNo: 'TC-65-012',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'TC',
			status: 'Payment',
			sample: 'ECMU-IIOT',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: 'WiFi 2.4 GHz',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 13,
			jobNo: 'TC-65-013',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'TC',
			status: 'Payment',
			sample: 'อากาศยานซึ่งไม่มีนักบิน (Unmanned Aerial Vehicle)',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: 'WiFi 2.4 GHz',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 14,
			jobNo: 'TC-65-014',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'TC',
			status: 'Result',
			sample: 'Bluetooth Module',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '*Bluetooth 2.4GHz',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 15,
			jobNo: 'TC-65-015',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'TC',
			status: 'Result',
			sample: 'Sigfox Monarch Module',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '*Bluetooth 2.4GHz',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 16,
			jobNo: 'TC-65-016',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'TC',
			status: 'Result',
			sample: 'UNAI UWB (ANCHOR & TAG)',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark:
				'ETSI EN 302 065-1 Electromagnetic Field (Ultra-wideband 1.6 – 10.6 GHz)+Report',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 17,
			jobNo: 'TC-65-017',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'TC',
			status: 'Payment',
			sample: 'Solar Pump Inverter',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 18,
			jobNo: 'TC-65-018',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'TC',
			status: 'Result',
			sample: 'LoRaWAN Hotspot',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 19,
			jobNo: 'TC-65-019',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'TC',
			status: 'Result',
			sample: 'SIMCom Module',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '*Standard: EN62311 : 2008',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
		{
			id: 20,
			jobNo: 'TC-65-020',
			progress: 5,
			qrcode: 'assets/images/qrcode/qrcode.png',
			sampleNo: '2200001',
			service: 'TC',
			status: 'Result',
			sample: '4G Spotlight WiFi IP Camera',
			colour: 'PXXXX',
			articleNo: 'EUT',
			noOfSample: '1',
			otherTest: '',
			remark: '',
			quatationNo: '',
			invoiceNo: '',
			dateReceivedSample: '21 May 2565',
			dateReceivedForm: 'Suriyan Srimart',
			paymentDueDate: '21 May 2565',
			dueDate: '21 May 2565',
			companyName: 'HG Robotics Co.,Ltd.',
			deliveryForTestReport: 'ขอรับคืนด้วยตนเอง',
			equipment: true,
			personnelAndAvailability: true,
			testMethod: true,
			submittingResults: true,
			generalityOfSample: 'ปกติ',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
			receivedDate: '20 May 2565 15:00:00',
			receivedBy: 'Suriyan Srimart',
			returnDate: '',
			returnBy: '',
			destroyedDate: '',
			sampleDescription: '',
			notes: '',
		},
	];
}
