import { faker } from '@faker-js/faker';

function getData() {
	const data = [];

	for (let i = 1; i <= 10; i++) {
		data.push({
			id: faker.datatype.number({ min: 1, max: 9999 }),
			group: faker.name.jobType(),
		});
	}
	return data;
}

export class GroupsFakeData {
	public static groups = [getData()];
}
