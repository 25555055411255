// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  apiUrl:"https://www.mea.ths-true.com/api",
  baseUrl:"https://www.mea.ths-true.com/api",
  imagePath:"https://www.ths-true.com/mea/uploads",
  ssoBaseUrl:"https://www.mea.ths-true.com/api",
  sysName:"MEA Tracking",
  sysVersion:"1.0",
  client_id:"mea-client",
  client_secret:"20bd501c-7276-4c1f-a687-b08d8779174e",
  regis_secret:"dm1zbG9jYWw6UEBzc3cwcmQ=",
  realm_id:"meatracking",
  grant_type:"password",
  reCAPTCHAV2siteKey:'6LfegzklAAAAAKzCDXnWXFOtnHmJe7gt4HKcgr8Q',
  tiny_key:'1agqtxexy3yjhj6y55jj44efuo1dqz4p2dheqoe1c3hlow2p'


	// production: false,
	// hmr: false,
	// apiUrl: 'http://localhost:3000/api',
	// baseUrl: 'http://localhost:3000/api',
	// ssoBaseUrl: 'http://localhost:3000/api',
	// imagePath: 'http://localhost:3000/uploads',
	// sysName: 'MEA Tracking',
	// sysVersion: '1.0',
	// client_id: 'mea-client',
	// client_secret: '20bd501c-7276-4c1f-a687-b08d8779174e',
	// regis_secret: 'dm1zbG9jYWw6UEBzc3cwcmQ=',
	// realm_id: 'meatracking',
	// grant_type: 'password',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
