import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '@core/services/http.service';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  public currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient, private http: HttpService, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is Customer
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Customer;
  }

  /**
   * User login
   *
   * @param username
   * @param password
   * @returns user
   */
  login(username: string, password: string): Promise<any> {
    let value = "username=" + username + "&password=" + password;
    let token
    let user
    console.log('login',value)
    return new Promise((resolv, reject) => {
      this.http.getHttpUrlEncode('auth/signin', value).then(res => {
        console.log('user 111111', res)
        // login successful if there's a jwt token in the response
        if (res.result.accessToken) {
          token = res.result.accessToken
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.http.setLocalStorageOne('token', token).then(res => {
            console.log('token1', token)
            this.http.getHttpJsonGet('auth/profile', token).then(async res => {
              let result = res.result
              let user_group:any=""
              await this.http.getHttpJsonGet('group-user/'+result.role,token).then(res=>{
                console.log('user_group',res)
                user_group = res.result.data[0].name
              })
              user = {
                id: result.id,
                username: result.username,
                email: result.email,
                firstName: result.firstname,
                password: "admin",
                lastName: result.lastname,
                avatar: environment.imagePath + '/user/image/' + result.image,
                user_group:user_group,
                address: result.address,
                role: result.role,
                token: token
              }
              // notify
              this.currentUserSubject.next(user);
              localStorage.setItem('currentUser', JSON.stringify(user));
              console.log('profile', res)
              // store user details and jwt token in local storage to keep user logged in between page refreshe
              console.log("login user", result)
              return resolv(result);
            },(e)=>{
              console.log('error',e)
              return reject(e)
            })
          });
          // user = {
          //   id: 1,
          //   username: 'admin',
          //   email: 'suriyan@ssj.co.th',
          //   firstName: 'suriyan',
          //   password: "admin",
          //   lastName: 'srimat',
          //   avatar: '',
          //   address: '',
          //   role: 'Admin',
          //   token: token
          // }
          // // notify
          // this.currentUserSubject.next(user);
          // localStorage.setItem('currentUser', JSON.stringify(user));
          // console.log('profile', user)          
        }
      })
    })
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
