export const locale = {
	lang: "en",
	data: {
	  SECTION:{
		MAIN:"Main",
		SYS:"Setting"
	  },
	  MENU: {
		APPS:{
			DASHBOARD: "Dashboard",
			TRACKING: "Tracking",
			SERVICES: "Services",
			CUSTOMER: "Customer",
			RESERVATION: "Reservation",
			TEST_REQUEST: "Test Request",
			TEST: "Tests",
			TEST_PLAN: "Test Plan",
			SAMPLES: "Samples",
			TEST_SETTING: "Test Settings",
			TEST_LAB: "Test Labs",
			TEST_EQUIPMENT: "Test Equipment",
			TEST_SERVICE: "Test Service",
			TEST_GROUP: "Test Group",
			TEST_ITEM: "Test Item",
			PAYMENT: "Payments",
			FILE_MANAGER: "File Manager",
			MANAGEMENT: "Management",
			PERFORMANCE: "Performance",
			MONITOR: "Monitor",
			REPORT: "Report",
			NEWS: "News",
			PROMOTIONS: "Promotions"
		},
		SYSTEM: {
		  USERS: "Users",
		  USER_GROUP: "User group",
		  REPORT_TEMPLATE: "Report template",
		  FORM_TEMPLATE: "form template",
		  SETTINGS: "Setting",
		  LOGOUT: "Logout"
		},
	  },
	},
  };
  