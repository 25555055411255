import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { environment } from 'environments/environment';
import { interval } from 'rxjs';
const numbers = interval(10000);

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;
  public notis=[];
  public imgPath = environment.imagePath+"/user/image/"

  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(private _notificationsService: NotificationsService) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit() {
    await this._notificationsService.onApiDataChange.subscribe(res => {
      this.notifications = res;
    });
    const takeFourNumbers = numbers.pipe();
    takeFourNumbers.subscribe(x => {
      this._notificationsService.getNoti().then(res=>{
        this.notis = res;
        console.log("noti loop",res)
      })
    });

    await this._notificationsService.getNoti().then(res=>{
      this.notis = res;
      console.log("res",res)
    })
  }
}
