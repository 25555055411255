import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from '@core/services/http.service';

import { AuthenticationService } from 'app/auth/service';
import { EMPTY } from 'rxjs';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private http:HttpService,
    private _authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if ([401, 403].indexOf(err.status) !== -1) {
          this._router.navigate(['/pages/miscellaneous/not-authorized']);
         // this.http.getHttpJsonGet('labs/sat-emer/h1','').then(res=>{

         // })
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    
         // this._router.navigate(['/pages/authentication/login']);
    
          // ? Can also logout and reload if needed
        //  this._authenticationService.logout();
          //location.reload();
          // let error = "Unauthorized"
          // this._router.navigate(['/pages/authentication/login'],{queryParams: {msg_rror: error}});
          
        }
        // throwError
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
