import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _http:HttpService
    ) {
    this.onApiDataChange = new BehaviorSubject('');
    this.getNotificationsData();
  }

  /**
   * Get Notifications Data
   */
  getNotificationsData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/notifications-data').subscribe((response: any) => {
        this.apiData = response;
        this.onApiDataChange.next(this.apiData);
        resolve(this.apiData);
      }, reject);
    });
  }

  getNoti(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http.getLocalStorageInfo("token").then(token=>{
        console.log('getNoti token',token);
      if(token!=undefined){
        this._http.getHttpJsonGet('notification/1',token).then(res=>{
          this.onApiDataChange.next(res.result.data);
          console.log('notification 222',res)
          resolve(res.result.data);
        }, reject);
      }

    });
    });
  }
}
