import { Component, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-navbar-cart',
	templateUrl: './navbar-cart.component.html',
})
export class NavbarCartComponent implements OnInit {
	// Public
	public products = [];
	public cartList = [];
	public cartListLength;

	// Private
	private _unsubscribeAll: Subject<any>;

	// Public Methods
	// -----------------------------------------------------------------------------------------------------

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Get Products
	}
}
