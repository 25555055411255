import {
	idNumber,
	name,
	jobTitle,
	booleanStatus,
	productPrice,
	wordFake,
	datePast,
} from 'libs/faker';

function getData() {
	const data = [];

	for (let i = 1; i <= 20; i++) {
		data.push({
			id: idNumber(99),
			name: jobTitle(),
			enabled: booleanStatus(),
			discount: productPrice(1, 100),
			dateStart: datePast(1),
			dateEnd: datePast(0),
			service: wordFake(),
			addedBy: name(),
			dateAdded: datePast(2),
			modifieldBy: name(),
			dateModifield: datePast(3),
		});
	}
	return data;
}

export class PromotionsFakeData {
	public static promotions = [getData()];
}
