<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up">{{
      notis?.length
    }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ notis?.length }} New
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <a class="d-flex" href="javascript:void(0)" *ngFor="let message of notis">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div class="avatar">
              <img [src]="imgPath+message.image" alt="avatar" width="62" height="62" />
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="message.topic"></p>
            <small class="notification-text">{{ message.msg }}</small>
            <p> <small class="notification-text">{{ message.created | date:'fullDate' }}</small></p>
          </div>
        </div></a
      >
    </li>
    <!--/ Notifications content -->


  </ul>
</li>
