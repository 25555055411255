import {
	idNumber,
	name,
	jobTitle,
	product,
	avatar,
	datePast,
} from 'libs/faker';

function getData() {
	const data = [];

	for (let i = 1; i <= 20; i++) {
		data.push({
			id: idNumber(99),
			name: jobTitle(),
			code: product(),
			addedBy: name(),
			addedByAvatar: avatar(),
			dateAdded: datePast(2),
			modifieldBy: name(),
			modifieldByAvatar: avatar(),
			dateModifield: datePast(3),
		});
	}
	return data;
}

export class TestServiceFakeData {
	public static testService = [getData()];
}
