export class TestCalendarFakeData {
	public static events = [
		{
			id: 1,
			url: '',
			title: 'Design Review',
			start: new Date(),
			end: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
			allDay: false,
			testCalendar: 'EM',
			extendedProps: {},
		},
		{
			id: 2,
			url: '',
			title: 'Meeting With Client',
			start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -11),
			end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -10),
			allDay: true,
			testCalendar: 'SC',
			extendedProps: {},
		},
		{
			id: 3,
			url: '',
			title: 'CL',
			allDay: true,
			start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -9),
			end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -7),
			testCalendar: 'PR',
			extendedProps: {},
		},
		{
			id: 4,
			url: 'https://www.pixinvent.com',
			title: 'URL event',
			start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -11),
			end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -10),
			allDay: true,
			testCalendar: 'EM',
			extendedProps: {},
		},
		{
			id: 5,
			url: '',
			title: 'Dart Game?',
			start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -13),
			end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -12),
			allDay: true,
			testCalendar: 'SC',
			extendedProps: {
				location: 'Chicago',
				description: 'on a Trip',
				addGuest: [],
			},
		},
		{
			id: 6,
			url: '',
			title: 'Meditation',
			start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -13),
			end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -12),
			allDay: true,
			testCalendar: 'Personal',
			extendedProps: {},
		},
		{
			id: 7,
			url: '',
			title: 'Dinner',
			start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -13),
			end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -12),
			allDay: true,
			testCalendar: 'RD',
			extendedProps: {
				location: 'Moscow',
				description: 'The party club',
			},
		},
		{
			id: 8,
			url: '',
			title: 'Product Review',
			start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -13),
			end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -12),
			allDay: true,
			testCalendar: 'TC',
			extendedProps: {
				location: 'Japan',
				description: 'weekend Drive',
			},
		},
		{
			id: 15,
			url: '',
			title: 'Testing Review',
			start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -13),
			end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -12),
			allDay: true,
			testCalendar: 'OT',
			extendedProps: {
				location: 'Japan',
				description: 'weekend Drive',
			},
		},
		{
			id: 9,
			url: '',
			title: 'Monthly Meeting',
			start:
				new Date().getMonth() === 11
					? new Date(new Date().getFullYear() + 1, 0, 1)
					: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
			end:
				new Date().getMonth() === 11
					? new Date(new Date().getFullYear() + 1, 0, 1)
					: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
			allDay: true,
			testCalendar: 'SS',
			extendedProps: {},
		},
		{
			id: 10,
			url: '',
			title: 'Monthly Checkup',
			start:
				new Date().getMonth() === 11
					? new Date(new Date().getFullYear() - 1, 0, 1)
					: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
			end:
				new Date().getMonth() === 11
					? new Date(new Date().getFullYear() - 1, 0, 1)
					: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
			allDay: true,
			testCalendar: 'EM',
			extendedProps: {},
		},
		{
			id: 11,
			url: '',
			title: 'Monthly Meeting',
			start:
				new Date().getMonth() === 11
					? new Date(new Date().getFullYear() + 1, 0, 1)
					: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
			end:
				new Date().getMonth() === 11
					? new Date(new Date().getFullYear() + 1, 0, 1)
					: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
			allDay: true,
			testCalendar: 'PR',
			extendedProps: {},
		},
		{
			id: 12,
			url: '',
			title: 'Monthly Checkup',
			start:
				new Date().getMonth() === 11
					? new Date(new Date().getFullYear() - 1, 0, 1)
					: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
			end:
				new Date().getMonth() === 11
					? new Date(new Date().getFullYear() - 1, 0, 1)
					: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
			allDay: true,
			testCalendar: 'AT',
			extendedProps: {},
		},
		{
			id: 13,
			url: '',
			title: 'Testing Meeting',
			start:
				new Date().getMonth() === 11
					? new Date(new Date().getFullYear() + 1, 0, 1)
					: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
			end:
				new Date().getMonth() === 11
					? new Date(new Date().getFullYear() + 1, 0, 1)
					: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
			allDay: true,
			testCalendar: 'RD',
			extendedProps: {},
		},
		{
			id: 14,
			url: '',
			title: 'Testing Checkup',
			start:
				new Date().getMonth() === 11
					? new Date(new Date().getFullYear() - 1, 0, 1)
					: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
			end:
				new Date().getMonth() === 11
					? new Date(new Date().getFullYear() - 1, 0, 1)
					: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
			allDay: true,
			testCalendar: 'EM',
			extendedProps: {},
		},
	];
	public static testCalendar = [
		{
			id: 1,
			filter: 'EM',
			title: 'EM',
			subtitle: 'Emc',
			color: 'bullet-success',
			checked: true,
		},
		{
			id: 2,
			filter: 'ST',
			title: 'ST',
			subtitle: 'Product Safety',
			color: 'bullet-warning',
			checked: false,
		},
		{
			id: 3,
			filter: 'SC',
			title: 'SC',
			subtitle: 'Solar Cell',
			color: 'bullet-danger',
			checked: false,
		},
		{
			id: 4,
			filter: 'RD',
			title: 'RD',
			subtitle: 'Diasnotic',
			color: 'bullet-secondary',
			checked: false,
		},
		{
			id: 5,
			filter: 'CL',
			title: 'CL',
			subtitle: 'Calibration',
			color: 'bullet-primary',
			checked: false,
		},
		{
			id: 6,
			filter: 'PR',
			title: 'PR',
			subtitle: 'Proformance',
			color: 'bullet-primary',
			checked: false,
		},
		{
			id: 7,
			filter: 'TC',
			title: 'TC',
			subtitle: 'Telecommunication',
			color: 'bullet-primary',
			checked: false,
		},
		{
			id: 8,
			filter: 'AT',
			title: 'AT',
			subtitle: 'Automotive',
			color: 'bullet-primary',
			checked: false,
		},
		{
			id: 9,
			filter: 'SS',
			title: 'SS',
			subtitle: 'Site Survey',
			color: 'bullet-primary',
			checked: false,
		},
		{
			id: 10,
			filter: 'OT',
			title: 'OT',
			subtitle: 'Other',
			color: 'bullet-primary',
			checked: false,
		},
	];
}
