import { faker } from '@faker-js/faker';

export const imageUrl = (width?, height?): string =>
	width && height
		? faker.image.imageUrl(width, height)
		: faker.image.imageUrl();

export const name = (): string => faker.name.findName();

export const jobTitle = (): string => faker.name.jobTitle();

export const idNumber = (max?, min?: 1): number =>
	faker.datatype.number({ max: max, min: min });

export const colorCode = (): string => faker.internet.color();

export const phoneNumber = (): string => faker.phone.number();

export const booleanStatus = (): boolean => faker.datatype.boolean();

export const datePast = (years?, current?): Date =>
	faker.date.past(years, current);

export const dateCurrent = (days?, refDate?: number): Date =>
	faker.date.past(days, refDate);

export const companyName = (): string => faker.company.companyName();
export const companySuffix = (): string => faker.company.companySuffix();

export const product = (): string => faker.commerce.product();
export const productName = (): string => faker.commerce.productName();
export const services = (): string =>
	faker.helpers.regexpStyleStringParse('SERVICE-[1-5]');
export const productPrice = (min?, max?): string =>
	faker.commerce.price(min, max);

export const wordFake = (): string => faker.random.word();
export const wordsFake = (count?: number): string => faker.random.words(count);

export const avatar = (): string => faker.image.avatar();

export const transactionType = (): string => faker.finance.transactionType();
export const amount = (min?, max?, dec?, symbol?): string =>
	faker.finance.amount(min, max, dec, symbol);

export default {
	name,
	imageUrl,
	jobTitle,
	idNumber,
	colorCode,
	phoneNumber,
	booleanStatus,
	datePast,
	dateCurrent,
	product,
	productName,
	services,
	productPrice,
	companyName,
	companySuffix,
	wordFake,
	wordsFake,
	avatar,
	transactionType,
	amount,
};
