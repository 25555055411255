export class PaymentsFakeData {
	public static payments = [
		{
			id: 1,
			jobNo: 'MEA0000XXX-A',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '0202-3153184 # 107 / 086-3653242',
			status: 'UnPaid',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 2,
			jobNo: 'MEA0000XXX-B',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '099-999-9999',
			status: 'UnPaid',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 3,
			jobNo: 'MEA0000XXX-C',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '082-486-2537',
			status: 'Paided',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 4,
			jobNo: 'MEA0000XXX-D',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'COD',
			company: 'BizQSoft Co.,LTD',
			contactNo: '082-486-2537',
			status: 'UnPaid',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 5,
			jobNo: 'MEA0000XXX-E',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '082-486-2537',
			status: 'UnPaid',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 6,
			jobNo: 'MEA0000XXX-F',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Credit Card',
			company: 'BizQSoft Co.,LTD',
			contactNo: '082-486-2537',
			status: 'Paided',
			email: '',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 7,
			jobNo: 'MEA0000XXX-G',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Credit Card',
			company: 'BizQSoft Co.,LTD',
			contactNo: '082-486-2537',
			status: 'Paided',
			email: '',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 8,
			jobNo: 'MEA0000XXX-H',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '082-486-2537',
			status: 'Paided',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 9,
			jobNo: 'MEA0000XXX-I',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '082-486-2537',
			status: 'Paided',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 10,
			jobNo: 'MEA0000XXX-J',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '099-999-9999',
			status: 'UnPaid',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 11,
			jobNo: 'MEA0000XXX-K',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '099-999-9999',
			status: 'UnPaid',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 12,
			jobNo: 'MEA0000XXX-L',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '099-999-9999',
			status: 'Paided',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 13,
			jobNo: 'MEA0000XXX-M',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Credit Card',
			company: 'BizQSoft Co.,LTD',
			status: 'UnPaid',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 14,
			jobNo: 'MEA0000XXX-N',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '099-999-9999',
			status: 'Paided',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 15,
			jobNo: 'MEA0000XXX-O',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '099-999-9999',
			status: 'Paided',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 16,
			jobNo: 'MEA0000XXX-P',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '099-999-9999',
			status: 'Paided',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 17,
			jobNo: 'MEA0000XXX-Q',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '099-999-9999',
			status: 'Paided',
			email: '',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 18,
			jobNo: 'MEA0000XXX-R',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '099-999-9999',
			status: 'Paided',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 19,
			jobNo: 'MEA0000XXX-S',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			contactNo: '099-999-9999',
			status: 'Paided',
			email: '',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
		{
			id: 20,
			jobNo: 'MEA0000XXX-T',
			dueDate: '21 May 2565',
			paidDate: '24 May 2565',
			total: 'XX,000',
			questionNo: 'QT000XXXX',
			invoiceNo: 'INV0000XXX',
			paymentMethod: 'Bank Transfer',
			company: 'BizQSoft Co.,LTD',
			status: 'UnPaid',
			email: 'email@email.com',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifieldBy: 'Lorem Ispum',
			dateModifield: '23 May 2565 13:00:00',
		},
	];
}
