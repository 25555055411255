export const locale = {
	lang: "th",
	data: {
	  SECTION:{
		MAIN:"หน้าหลัก",
		SYS:"ตั้งค่า"
	  },
	  MENU: {
		APPS:{
			DASHBOARD: "สรุปภาพ",
			TRACKING: "ติดตามสถานะ",
			SERVICES: "บริการ",
			CUSTOMER: "รายการลูกค้า",
			RESERVATION: "จองห้องทดสอบ",
			TEST_REQUEST: "ขอรับบริการ",
			TEST: "ระบบทดสอบ",
			TEST_PLAN: "วางแผนการทดสอบ",
			SAMPLES: "ตัวอย่างทดสอบ",
			TEST_SETTING: "ตั้งค่า",
			TEST_LAB: "ห้องทดสอบ",
			TEST_EQUIPMENT: "เครื่องมือทดสอบ",
			TEST_SERVICE: "ประเภทการทดสอบ",
			TEST_GROUP: "กลุ่มการทดสอบ",
			TEST_ITEM: "หัวข้อการทดสอบ",
			PAYMENT: "การชำระเงิน",
			FILE_MANAGER: "จัดการข้อมูล",
			MANAGEMENT: "จัดการ",
			PERFORMANCE: "ประสิทธิภาพ",
			MONITOR: "สถานะห้องทดสอบ",
			REPORT: "รายงาน",
			NEWS: "ข่าวสาร",
			PROMOTIONS: "โปรโมชั่น"
		},
		SYSTEM: {
		  USERS: "จัดการผู้ใช้",
		  USER_GROUP: "กลุ่มผู้ใช้",
		  REPORT_TEMPLATE: "รายงาน เทมเพล็ต",
		  FORM_TEMPLATE: "ฟอร์ม เทมเพล็ต",
		  SETTINGS: "ตั้งค่า",
		  LOGOUT: "ออกจากระบบ"
		},
	  },
	},
  };
  