import {
	idNumber,
	name,
	jobTitle,
	wordsFake,
	avatar,
	datePast,
} from 'libs/faker';

function getData() {
	const data = [];

	for (let i = 1; i <= 20; i++) {
		data.push({
			id: idNumber(99),
			services: wordsFake(2),
			name: jobTitle(),
			order: idNumber(20),
			addedBy: name(),
			addedByAvatar: avatar(),
			dateAdded: datePast(2),
			modifieldBy: name(),
			modifieldByAvatar: avatar(),
			dateModifield: datePast(3),
		});
	}
	return data;
}

export class TestGroupFakeData {
	public static testGroup = [getData()];
}
