import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
	// Apps & Pages
	//Admin = 1, Customer = 2,Testing = 3 , Manager = 4,Maketing=5,Finace=6,Director=7,Report=8
	// To set multiple role: ['1', '2']
	{
		id: 'apps',
		title: 'Main menu',
		translate: 'SECTION.MAIN',
		type: 'section',
		icon: 'package',
		children: [
			{
				id: 'dashboard',
				title: 'Dashboard',
				translate: 'MENU.APPS.DASHBOARD',
				type: 'item',
				icon: 'home',
				url: 'dashboard',
				role:[] //? To set multiple role: ['Admin', 'Client']
			},
			{
				id: 'tracking',
				title: 'Tracking',
				translate: 'MENU.APPS.TRACKING',
				type: 'item',
				icon: 'trending-up',
				url: 'apps/tracking/all',
				role:[]
			},
			{
				id: 'services',
				title: 'Services',
				translate: 'MENU.APPS.SERVICES',
				icon: 'truck',
				type: 'collapsible',
				role:[],
				children: [
					{
						id: 'customer',
						title: 'Customer',
						translate: 'MENU.APPS.CUSTOMER',
						type: 'item',
						icon: 'arrow-right',
						url: 'apps/customer',
						role:['1','2','3','4','5','6','7','8']
					},
					{
						id: 'reservation',
						title: 'Reservation',
						translate: 'MENU.APPS.RESERVATION',
						type: 'item',
						icon: 'arrow-right',
						url: 'apps/reservation',
						role:['1','2','3','4','5','6','7','8']
					},
					{
						id: 'testRequest',
						title: 'Test Request',
						translate: 'MENU.APPS.TEST_REQUEST',
						type: 'item',
						icon: 'arrow-right',
						url: 'apps/test-request',
						role:['1','2','3','4','5','6','7','8']
					},
				],
			},
			{
				id: 'tests',
				title: 'Tests',
				translate: 'MENU.APPS.TEST',
				type: 'collapsible',
				icon: 'clipboard',
				role:[],
				children: [
					
					{
						id: 'testPlan',
						title: 'Test Plan',
						translate: 'MENU.APPS.TEST_PLAN',
						type: 'item',
						icon: 'arrow-right',
						url: 'apps/test-plan',
						role:['1','2','3','4','5','6','7','8']
					},
					{
						id: 'samples',
						title: 'Samples',
						translate: 'MENU.APPS.SAMPLES',
						type: 'item',
						icon: 'arrow-right',
						url: 'apps/test-sample',
						role:['1','2','3','4','5','6','7','8']
					}
					
				],
			},
			{
				id: 'testSettings',
				title: 'Test Settings',
				translate: 'MENU.APPS.TEST_SETTING',
				type: 'collapsible',
				icon: 'twitch',
				role:[],
				children: [
					{
						id: 'testLab',
						title: 'Test Labs',
						translate: 'MENU.APPS.TEST_LAB',
						type: 'item',
						icon: 'arrow-right',
						url: 'apps/test-labs',
						role:['1','2','3','4','5','6','7','8']
					},
					{
						id: 'testEquipment',
						title: 'Test Equipment',
						translate: 'MENU.APPS.TEST_EQUIPMENT',
						type: 'item',
						icon: 'arrow-right',
						url: 'apps/test-equipment',
						role:['1','2','3','4','5','6','7','8']
					},
					{
						id: 'testService',
						title: 'Test Service',
						translate: 'MENU.APPS.TEST_SERVICE',
						type: 'item',
						icon: 'arrow-right',
						url: 'apps/test-service',
						role:['1','2','3','4','5','6','7','8']
					},
					{
						id: 'testGroup',
						title: 'Test Group',
						translate: 'MENU.APPS.TEST_GROUP',
						type: 'item',
						icon: 'arrow-right',
						url: 'apps/test-group',
						role:['1','2','3','4','5','6','7','8']
					},
					{
						id: 'testItem',
						title: 'Test Item',
						translate: 'MENU.APPS.TEST_ITEM',
						type: 'item',
						icon: 'arrow-right',
						url: 'apps/test-item',
						role:['1','2','3','4','5','6','7','8']
					},
				],
			},
			{
				id: 'payments',
				title: 'Payments',
				translate: 'MENU.APPS.PAYMENT',
				type: 'item',
				icon: 'shopping-bag',
				url: 'apps/payments',
				role:[]
			},
			{
				id: 'fileManager',
				title: 'File Manager',
				translate: 'MENU.APPS.FILE_MANAGER',
				type: 'item',
				icon: 'archive',
				url: 'apps/filemanager',
				role:[]
			},
			{
				id: 'management',
				title: 'Management',
				translate: 'MENU.APPS.MANAGEMENT',
				type: 'collapsible',
				icon: 'box',
				role:[],
				children: [
					{
						id: 'performance',
						title: 'Performance',
						translate: 'MENU.APPS.PERFORMANCE',
						type: 'item',
						icon: 'arrow-right',
						url: 'apps/performance',
						role:['1','2','3','4','5','6','7','8']
					},
					{
						id: 'monitor',
						title: 'Monitor',
						translate: 'MENU.APPS.MONITOR',
						type: 'item',
						icon: 'arrow-right',
						url: 'apps/monitor',
						role:['1','2','3','4','5','6','7','8']
					},
					{
						id: 'reportFirst',
						title: 'Report',
						translate: 'MENU.APPS.REPORT',
						type: 'item',
						icon: 'arrow-right',
						url: 'apps/reports',
						role:['1','2','3','4','5','6','7','8']
					}
					
				],
			},
			{
				id: 'news',
				title: 'News',
				translate: 'MENU.APPS.NEWS',
				type: 'item',
				icon: 'book',
				url: 'apps/news',
				role:[]
			},
			{
				id: 'promotions',
				title: 'Promotions',
				translate: 'MENU.APPS.PROMOTIONS',
				type: 'item',
				icon: 'package',
				url: 'apps/promotions',
				role:[]
			}
			
		],
	},
	// System setting
	{
		id: 'settings',
		title: 'Settings',
		type: 'section',
		translate: 'SECTION.SYS',
		icon: 'layers',
		children: [
			{
				id: 'users',
				title: 'Users',
				translate: 'MENU.SYSTEM.USERS',
				type: 'item',
				icon: 'users',
				url: 'apps/users',
				role:[]
			},
			{
				id: 'user-group',
				title: 'User group',
				translate: 'MENU.SYSTEM.USER_GROUP',
				type: 'item',
				icon: 'monitor',
				url: 'apps/user-group',
				role:[]
			},
			{
				id: 'report-template',
				title: 'Report template',
				translate: 'MENU.SYSTEM.REPORT_TEMPLATE',
				type: 'item',
				icon: 'settings',
				url: 'apps/report-template',
				role:[]
			},
			{
				id: 'form-template',
				title: 'form template',
				translate: 'MENU.SYSTEM.FORM_TEMPLATE',
				type: 'item',
				icon: 'briefcase',
				url: 'apps/form-template',
				role:[]
			}
		],
	},
];
