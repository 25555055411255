import { faker } from '@faker-js/faker';

function getData() {
	const data = [];

	for (let i = 1; i <= 10; i++) {
		data.push({
			id: faker.datatype.number({ min: 1, max: 10 }),
			name: faker.system.fileType(),
			value: faker.system.fileName(),
			addedBy: faker.name.findName(),
			addedByAvatar: faker.image.avatar(),
			dateAdded: faker.date.recent(1),
			modifieldBy: faker.name.findName(),
			modifieldByAvatar: faker.image.avatar(),
			dateModifield: faker.date.recent(2),
		});
	}
	return data;
}

export class SystemsFakeData {
	public static systems = [getData()];
}
