import { InMemoryDbService } from 'angular-in-memory-web-api';

import { accountSettingsFakeData } from '@fake-db/account-settings.data';
import { CalendarFakeData } from '@fake-db/calendar.data';
import { ReservationFakeData } from '@fake-db/reservation.data';
import { TestCalendarFakeData } from '@fake-db/test-calendar.data';
import { DashboardFakeData } from '@fake-db/dashboard.data';
import { InvoiceFakeData } from '@fake-db/invoice.data';
import { NotificationsFakeData } from '@fake-db/notifications.data';
import { ProfileFakeData } from '@fake-db/profile.data';
import { SearchFakeData } from '@fake-db/search.data';
import { TrackingFakeData } from '@fake-db/tracking.data';
import { UsersFakeData } from '@fake-db/users.data';
import { GroupsFakeData } from '@fake-db/groups.data';
import { SystemsFakeData } from '@fake-db/systems.data';
import { CustomerCategoriesFakeData } from '@fake-db/customer-categories.data';
import { CustomerGroupsFakeData } from '@fake-db/customer-groups.data';
import { ProductFakeData } from '@fake-db/product.data';
import { PaymentsFakeData } from '@fake-db/payment.data';
import { TestRequestFakeData } from '@fake-db/test-request.data';
import { TestPlanFakeData } from '@fake-db/test-plan.data';
import { TestSampleFakeData } from '@fake-db/test-sample.data';
import { CustomerFakeData } from '@fake-db/customer.data';
import { CatalogFakeData } from '@fake-db/product-catalog.data';
import { ProductUnitFakeData } from '@fake-db/product-unit.data';
import { TestLabFakeData } from '@fake-db/test-labs.data';
import { TestEquipmentFakeData } from '@fake-db/test-equipment.data';
import { TestServiceFakeData } from '@fake-db/test-service.data';
import { TestGroupFakeData } from '@fake-db/test-group.data';
import { TestItemFakeData } from '@fake-db/test-item.data';
import { NewsFakeData } from '@fake-db/news.data';
import { PromotionsFakeData } from '@fake-db/promotions.data';
import { ReportsFakeData } from '@fake-db/reports.data';
export class FakeDbService implements InMemoryDbService {
	createDb(): any {
		return {
			// Account Settings
			'account-settings-data': accountSettingsFakeData.data,

			// Profile
			'profile-data': ProfileFakeData.data,

			// Users
			'users-data': UsersFakeData.users,
			// Groups
			'groups-data': GroupsFakeData.groups,
			// Systems
			'systems-data': SystemsFakeData.systems,
			// Customer Categories
			'customer-categories-data': CustomerCategoriesFakeData.customerCategories,
			// Customer Groups
			'customer-groups-data': CustomerGroupsFakeData.customerGroups,

			// Product
			'product-data': ProductFakeData.products,

			// Payments
			'payment-data': PaymentsFakeData.payments,

			// Test Request
			'test-request-data': TestRequestFakeData.testRequest,

			// Test Plan
			'test-plan-data': TestPlanFakeData.testPlan,

			// Test Plan
			'test-sample-data': TestSampleFakeData.testSample,

			// Customer
			'customer-data': CustomerFakeData.customers,
			// product catalog
			'product-catalog-data': CatalogFakeData.catalogs,
			// product catalog
			'product-unit-data': ProductUnitFakeData.units,

			// Invoice
			'invoice-data': InvoiceFakeData.invoices,

			// Tracking
			'trackings-data': TrackingFakeData.tasks,
			'trackings-assignee': TrackingFakeData.assignee,
			'trackings-filters': TrackingFakeData.filters,
			'trackings-categories': TrackingFakeData.categories,

			// Calendar
			'calendar-events': CalendarFakeData.events,
			'calendar-filter': CalendarFakeData.calendar,

			// Reservation
			'reservation-events': ReservationFakeData.events,
			'reservation-filter': ReservationFakeData.reservation,

			// Calendar
			'test-calendar-events': TestCalendarFakeData.events,
			'test-calendar-filter': TestCalendarFakeData.testCalendar,

			// Search
			'search-data': SearchFakeData.search,

			// Notifications
			'notifications-data': NotificationsFakeData.data,

			// Dashboard
			'dashboard-data': DashboardFakeData.data,
			// Test Lab
			'test-lab-data': TestLabFakeData.testLab,
			// Equipment
			'test-equipment-data': TestEquipmentFakeData.testEquipment,
			// Service
			'test-service-data': TestServiceFakeData.testService,
			// Group
			'test-group-data': TestGroupFakeData.testGroup,
			// Item
			'test-item-data': TestItemFakeData.testItem,
			// News
			'news-data': NewsFakeData.news,
			// Promotion
			'promotions-data': PromotionsFakeData.promotions,
			// Reports
			'reports-data': ReportsFakeData.reports,
		};
	}
}
