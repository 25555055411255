import { faker } from '@faker-js/faker';

function getData() {
	const data = [];

	for (let i = 1; i <= 50; i++) {
		data.push({
			id: faker.datatype.number({ min: 1, max: 9999 }),
			enabled: faker.datatype.boolean(),
			name: faker.name.findName(),
			email: faker.internet.email(),
			group: faker.name.jobType(),
			phone: faker.phone.number('0##-###-####'),
			addedBy: faker.name.findName(),
			addedByAvatar: faker.image.avatar(),
			dateAdded: faker.date.recent(1),
			modifieldBy: faker.name.findName(),
			modifieldByAvatar: faker.image.avatar(),
			dateModifield: faker.date.recent(2),
		});
	}
	return data;
}

export class UsersFakeData {
	public static users = [getData()];
}
