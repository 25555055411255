import { faker } from '@faker-js/faker';

function getData() {
	const data = [];

	for (let i = 1; i <= 100; i++) {
		data.push({
			id: faker.datatype.number({ min: 1, max: 150 }),
			name: faker.commerce.product(),
		});
	}
	return data;
}

export class CustomerCategoriesFakeData {
	public static customerCategories = [getData()];
}
