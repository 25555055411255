import {
	idNumber,
	name,
	jobTitle,
	productName,
	avatar,
	datePast,
	wordFake,
} from 'libs/faker';

function getData() {
	const data = [];

	for (let i = 1; i <= 20; i++) {
		data.push({
			id: idNumber(99),
			code: productName(),
			name: jobTitle(),
			testTitle: wordFake(),
			order: idNumber(20),
			addedBy: name(),
			addedByAvatar: avatar(),
			dateAdded: datePast(2),
			modifieldBy: name(),
			modifieldByAvatar: avatar(),
			dateModifield: datePast(3),
		});
	}
	return data;
}

export class TestItemFakeData {
	public static testItem = [getData()];
}
