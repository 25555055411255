import { idNumber, name, jobTitle, booleanStatus, datePast } from 'libs/faker';

function getData() {
	const data = [];

	for (let i = 1; i <= 20; i++) {
		data.push({
			id: idNumber(99),
			name: jobTitle(),
			enabled: booleanStatus(),
			addedBy: name(),
			dateAdded: datePast(2),
			modifieldBy: name(),
			dateModifield: datePast(3),
		});
	}
	return data;
}

export class TestLabFakeData {
	public static testLab = [getData()];
}
