import {
	Component,
	Inject,
	OnDestroy,
	OnInit,
	ElementRef,
	Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as Waves from 'node-waves';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from '@core/services/translation.service';

import { menu } from 'app/menu/menu';
import { locale as menuEnglish } from 'app/menu/i18n/en';
import { locale as menuThai } from 'app/menu/i18n/th';
import { HttpService } from '@core/services/http.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { interval } from 'rxjs';
const numbers = interval(10000);

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	coreConfig: any;
	menu: any;
	defaultLanguage: 'en'; // This language will be used as a fallback when a translation isn't found in the current language
	appLanguage: 'en'; // Set application default language i.e fr

	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {DOCUMENT} document
	 * @param {Title} _title
	 * @param {Renderer2} _renderer
	 * @param {ElementRef} _elementRef
	 * @param {CoreConfigService} _coreConfigService
	 * @param {CoreSidebarService} _coreSidebarService
	 * @param {CoreLoadingScreenService} _coreLoadingScreenService
	 * @param {CoreMenuService} _coreMenuService
	 * @param {CoreTranslationService} _coreTranslationService
	 * @param {TranslateService} _translateService
	 */
	constructor(
		@Inject(DOCUMENT) private document: any,
		private _title: Title,
		private _renderer: Renderer2,
		private _elementRef: ElementRef,
		public _coreConfigService: CoreConfigService,
		private _coreSidebarService: CoreSidebarService,
		private _coreLoadingScreenService: CoreLoadingScreenService,
		private _coreMenuService: CoreMenuService,
		private _coreTranslationService: CoreTranslationService,
		private _translateService: TranslateService,
		private _httpService:HttpService,
		private router: Router,
		private _authenticationService: AuthenticationService
	) {

		// Add languages to the translation service
		this._translateService.addLangs(['en', 'fr', 'de', 'pt']);

		// This language will be used as a fallback when a translation isn't found in the current language
		this._translateService.setDefaultLang('en');

		// Set the translations for the menu
		this._coreTranslationService.translate(menuThai,menuEnglish);


		this.intervalLoopGetData();
		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	// Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------
	

	async intervalLoopGetData(){
		const takeFourNumbers = await numbers.pipe();
		takeFourNumbers.subscribe(x => {
			this.verifyToken().then(res=>{
				console.log('loop verified token',x,res)
			})
		});
	}	

	async setPermission():Promise<any>{
		return new Promise(async (resolve,reject)=>{
				//dashboard code = 1
				await this.getPermissionModule(1).then(res=>{this.menu[0].children[0].role = res})
		
				//tracking code = 2
				await this.getPermissionModule(2).then(res=>{this.menu[0].children[1].role = res})
				
				//service code = 3
				await this.getPermissionModule(3).then(res=>{this.menu[0].children[2].role = res})
				
				
				//test code = 4
				await this.getPermissionModule(4).then(res=>{this.menu[0].children[3].role = res})
				
				//test setting code = 5
				await this.getPermissionModule(5).then(res=>{this.menu[0].children[4].role = res})
				
				//payment code = 6

				await this.getPermissionModule(6).then(res=>{this.menu[0].children[5].role = res})
		
				//file manager code = 7
				await this.getPermissionModule(7).then(res=>{this.menu[0].children[6].role = res})
		
				//management code = 8
				await this.getPermissionModule(8).then(res=>{this.menu[0].children[7].role = res})
				
				//new code
				await this.getPermissionModule(9).then(res=>{this.menu[0].children[8].role = res})
		
				//promotion
				await this.getPermissionModule(10).then(res=>{this.menu[0].children[9].role = res})
		
		
				//users code = 11
				await this.getPermissionModule(11).then(res=>{this.menu[1].children[0].role = res})
				
				//user group
				await this.getPermissionModule(12).then(res=>{this.menu[1].children[1].role = res})
				
				//report template
				await this.getPermissionModule(14).then(res=>{this.menu[1].children[2].role = res})
		
				//form template
				await this.getPermissionModule(15).then(res=>{
					this.menu[1].children[3].role = res})
				resolve(true)
				console.log('res menu',this.menu)
		})

	}

	verifyToken():Promise<any>{
		return new Promise((resolv,reject)=>{
			this._httpService.getLocalStorageInfo("token").then(token=>{
				if(token!=undefined){
					console.log('verify token',token)
					this._httpService.getHttpJsonPost('auth/jwtDecode',{},token).then(res=>{
						if(res.error==true){
							console.log('verify token',res)
							//resolv(true)
							localStorage.removeItem('currentUser');
							this._authenticationService.currentUserSubject.next(null);
							this.router.navigate(['/pages/authentication/login']);
						}
					})
				}else{
					//resolv(true)
					localStorage.removeItem('currentUser');
					this._authenticationService.currentUserSubject.next(null);
					this.router.navigate(['/pages/authentication/login']);
				}
			})

			
		})

	}
	async getPermissionModule(code:any):Promise<[]>{
		return new Promise((resolve,reject)=>{
			this._httpService.getLocalStorageInfo('token').then(async token=>{
				console.log('get permission',token)
				if(token!=undefined){
					await this._httpService.getHttpJsonGet('group-module/permision/'+code,token).then(res=>{
						console.log('menu res 1',res.result.data)
						resolve(res.result.data)
					})
				}

			})
			
		})
	}
	/**
	 * On init
	 */
	async ngOnInit() {
		// Init wave effect (Ripple effect)
		Waves.init();
		//this.menu = menu;
		// Subscribe to config changes
		await this._coreConfigService.config
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config) => {
				this.coreConfig = config;	
				this.menu = menu;
				this.setPermission().then(async (res)=>{
				console.log("menu 111",this.menu,res)
				await this._coreMenuService.register('main', this.menu);
				await this._coreMenuService.setCurrentMenu('main');
				})

						// Get the application main menu


				// Set application default language.

				// Change application language? Read the ngxTranslate Fix

				// ? Use app-config.ts file to set default language
				const appLanguage = this.coreConfig.app.appLanguage || 'en';
				this._translateService.use(appLanguage);

				// ? OR
				// ? User the current browser lang if available, if undefined use 'en'
				// const browserLang = this._translateService.getBrowserLang();
				// this._translateService.use(browserLang.match(/en|fr|de|pt/) ? browserLang : 'en');

				/**
				 * ! Fix : ngxTranslate
				 * ----------------------------------------------------------------------------------------------------
				 */

				/**
				 *
				 * Using different language than the default ('en') one i.e French?
				 * In this case, you may find the issue where application is not properly translated when your app is initialized.
				 *
				 * It's due to ngxTranslate module and below is a fix for that.
				 * Eventually we will move to the multi language implementation over to the Angular's core language service.
				 *
				 **/

				// Set the default language to 'en' and then back to 'fr'.

				setTimeout(() => {
					this._translateService.setDefaultLang('en');
					this._translateService.setDefaultLang(appLanguage);
				});

				/**
				 * !Fix: ngxTranslate
				 * ----------------------------------------------------------------------------------------------------
				 */

				// Layout
				//--------

				// Remove default classes first
				this._elementRef.nativeElement.classList.remove(
					'vertical-layout',
					'vertical-menu-modern',
					'horizontal-layout',
					'horizontal-menu'
				);
				// Add class based on config options
				if (this.coreConfig.layout.type === 'vertical') {
					this._elementRef.nativeElement.classList.add(
						'vertical-layout',
						'vertical-menu-modern'
					);
				} else if (this.coreConfig.layout.type === 'horizontal') {
					this._elementRef.nativeElement.classList.add(
						'horizontal-layout',
						'horizontal-menu'
					);
				}

				// Navbar
				//--------

				// Remove default classes first
				this._elementRef.nativeElement.classList.remove(
					'navbar-static',
					'navbar-sticky'
				);

				// Add class based on config options
				if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
					this._elementRef.nativeElement.classList.add('navbar-static');
				} else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
					this._elementRef.nativeElement.classList.add('navbar-sticky');
				}

				// Footer
				//--------

				// Remove default classes first
				this._elementRef.nativeElement.classList.remove(
					'footer-fixed',
					'footer-static'
				);

				// Add class based on config options
				if (this.coreConfig.layout.footer.type === 'footer-sticky') {
					this._elementRef.nativeElement.classList.add('footer-fixed');
				} else if (this.coreConfig.layout.footer.type === 'footer-static') {
					this._elementRef.nativeElement.classList.add('footer-static');
				}

				// Blank layout
				if (
					this.coreConfig.layout.menu.hidden &&
					this.coreConfig.layout.navbar.hidden &&
					this.coreConfig.layout.footer.hidden
				) {
					this._elementRef.nativeElement.classList.add('blank-page');
					// ! Fix: Transition issue while coming from blank page
					this._renderer.setAttribute(
						this._elementRef.nativeElement.getElementsByClassName(
							'app-content'
						)[0],
						'style',
						'transition:none'
					);
				} else {
					this._elementRef.nativeElement.classList.remove('blank-page');
					// ! Fix: Transition issue while coming from blank page
					setTimeout(() => {
						this._renderer.setAttribute(
							this._elementRef.nativeElement.getElementsByClassName(
								'app-content'
							)[0],
							'style',
							'transition:300ms ease all'
						);
					}, 0);
					// If navbar hidden
					if (this.coreConfig.layout.navbar.hidden) {
						this._elementRef.nativeElement.classList.add('navbar-hidden');
					}
					// Menu (Vertical menu hidden)
					if (this.coreConfig.layout.menu.hidden) {
						this._renderer.setAttribute(
							this._elementRef.nativeElement,
							'data-col',
							'1-column'
						);
					} else {
						this._renderer.removeAttribute(
							this._elementRef.nativeElement,
							'data-col'
						);
					}
					// Footer
					if (this.coreConfig.layout.footer.hidden) {
						this._elementRef.nativeElement.classList.add('footer-hidden');
					}
				}

				// Skin Class (Adding to body as it requires highest priority)
				if (
					this.coreConfig.layout.skin !== '' &&
					this.coreConfig.layout.skin !== undefined
				) {
					this.document.body.classList.remove(
						'default-layout',
						'bordered-layout',
						'dark-layout',
						'semi-dark-layout'
					);
					this.document.body.classList.add(
						this.coreConfig.layout.skin + '-layout'
					);
				}
			});

		// Set the application page title
		this._title.setTitle(this.coreConfig.app.appTitle);
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	// Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar open
	 *
	 * @param key
	 */
	toggleSidebar(key): void {
		this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
	}
}
