export class TestPlanFakeData {
	public static testPlan = [
		{
			id: 1,
			jobNo: 'MEA0000XXX-A',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-0001',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Suriyan Srimart',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Suriyan Srimart',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 2,
			jobNo: 'MEA0000XXX-A',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 2,
			jobNo: 'MEA0000XXX-A',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 4,
			jobNo: 'MEA0000XXX-D',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 5,
			jobNo: 'MEA0000XXX-A',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 6,
			jobNo: 'MEA0000XXX-B',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 7,
			jobNo: 'MEA0000XXX-G',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 8,
			jobNo: 'MEA0000XXX-T',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 9,
			jobNo: 'MEA0000XXX-V',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 10,
			jobNo: 'MEA0000XXX-K',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 11,
			jobNo: 'MEA0000XXX-P',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 12,
			jobNo: 'MEA0000XXX-A',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 13,
			jobNo: 'MEA0000XXX-N',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 14,
			jobNo: 'MEA0000XXX-Q',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 15,
			jobNo: 'MEA0000XXX-O',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 16,
			jobNo: 'MEA0000XXX-P',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 17,
			jobNo: 'MEA0000XXX-Q',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 18,
			jobNo: 'MEA0000XXX-R',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 19,
			jobNo: 'MEA0000XXX-S',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
		{
			id: 20,
			jobNo: 'MEA0000XXX-T',
			progress: 5,
			sample: 'HDD Mobile DVR',
			colour: 'PXXXX',
			articleNo: 'EUT',
			testTitle: 'AT-Basic-xxxx',
			testNo: 'EMTR009',
			testItem: 'EN50121-4 (PFM)',
			dueDate: '21 May 2565',
			startDate: '24 May 2565',
			note: 'Notes by staff',
			addedby: 'Lorem Ispum',
			dateAdded: '21 May 2565 15:00:00',
			modifiedBy: 'Lorem Ispum',
			dateModified: '23 May 2565 13:00:00',
		},
	];
}
