import {
	idNumber,
	name,
	product,
	productName,
	jobTitle,
	booleanStatus,
	datePast,
	companyName,
	companySuffix,
	wordFake,
	avatar,
} from 'libs/faker';

function getData() {
	const data = [];

	for (let i = 1; i <= 20; i++) {
		data.push({
			id: idNumber(99),
			enabled: booleanStatus(),
			name: jobTitle(),
			idNo: productName(),
			lab: companySuffix(),
			service: wordFake(),
			company: companyName(),
			series: product(),
			traceability: wordFake(),
			dueDate: datePast(),
			addedBy: name(),
			addedByAvatar: avatar(),
			dateAdded: datePast(2),
			modifieldBy: name(),
			modifieldByAvatar: avatar(),
			dateModifield: datePast(3),
		});
	}
	return data;
}

export class TestEquipmentFakeData {
	public static testEquipment = [getData()];
}
