import {
	idNumber,
	jobTitle,
	product,
	productPrice,
	services,
	datePast,
	transactionType,
	companyName,
	amount,
} from 'libs/faker';

function getData() {
	const data = [];

	for (let i = 1; i <= 20; i++) {
		data.push({
			id: idNumber(99),
			name: jobTitle(),
			status: transactionType(),
			discount: productPrice(1, 100),
			dateStart: datePast(1),
			dateEnd: datePast(0),
			service: services(),
			serviceNo: product(),
			company: companyName(),
			dateAdded: datePast(),
			total: productPrice(1000, 50000),
			percent: amount(1, 50, 0, ''),
		});
	}
	return data;
}

export class ReportsFakeData {
	public static reports = [getData()];
}
