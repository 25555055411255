export enum Role {
  Admin = '1',
  Customer = '2',
  Testing = '3',
  Manager = '4',
  Marketing = '5',
  Finace = '6',
  Director = '7',
  Report = '8'
}
